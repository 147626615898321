import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import * as C from '../styles/elements';

const Hero = styled.section`
    grid-row: 3/4;
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    text-align: center;
    margin: 3rem 0 0 0;

    background: rgb(91,34,102);
    background: linear-gradient(180deg, rgba(91,34,102,1) 0%, rgba(102,34,96,1) 35%, rgba(200,43,112,1) 100%);
    background-size: cover;

    ${media.lessThan("large")`
        padding: 2rem 4rem;
    `}

    ${media.lessThan("medium")`
        padding: 2rem;
    `}

`

const Reason = () =>
    <Hero>
        <div style={{maxWidth:"100ch"}}><C.SubTitle><C.Color>A Razão Nº 1 Que Faz As Pessoas Não Conseguirem Emagrecer é <br/> Não Ter Prazer em Fazer Dieta.</C.Color></C.SubTitle></div>
        <div style={{maxWidth:"100ch"}}><C.Title style={{lineHeight: 1.3}}><C.BG>Há Alguns Anos, Descobri os Segredos Do Emagrecimento Saudável e Prazeroso</C.BG></C.Title></div>
        <C.Paragraph><C.Bolder><C.Color>...que transformaram a forma como eu via a Nutrição, e melhoraram muito os resultados dos meus pacientes.</C.Color></C.Bolder></C.Paragraph>
    </Hero>

export default Reason