import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { StaticImage } from 'gatsby-plugin-image';
import Youtube from './youtube';
import CTAButtonBuy from './CTAButtonBuy';
import {Color, Bolder} from '../styles/elements';

const CoverCopy = styled.div`
    display: grid;
    position: relative;
    grid-template-columns: repeat(6,1fr);
    height: 100vh;
    padding: 1rem;

    ${media.lessThan("large")`
        /* flex-direction: column; */
        align-items: start;
        justify-content: space-between;
    `}


`

const CoverBG = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(183deg, rgba(91,34,102,1) 0%, rgba(102,34,96,1) 35%, rgba(200,43,112,1) 100%);
    clip-path: polygon(100% 0%, 100% 100%, 12% 100%);

    ${media.lessThan("medium")`
        clip-path: polygon(100% 41.3%, 100% 100%, 0% 100%);
    `}
`

const CoverLogo = styled.div`
    grid-row: 1/2;
    grid-column: 3/5;
    height: auto;
    width: 10rem;
    margin: 0 auto 2rem;
    
    ${media.lessThan("medium")`
        width: 9rem;
        margin-bottom: 0;
        align-self: center;
    `}
`

const HeadlineWrapper = styled.div`
    grid-column: 1/4;
    grid-row: 2/5;
    z-index: 100;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 0 2rem;
    color: white;
    
    ${media.lessThan("large")`
        margin: 0 2rem 2rem;
        text-align: center;        
        align-items: center;
        grid-column: 1/-1;
        grid-row: 2/3;
    `}
        
        ${media.lessThan('700px')`
        /* grid-row: 2/6; */
        margin: 0;
        margin-top: 1rem;
        grid-column: 1/-1;
        grid-row: 2/3;
        padding: 0;
    `}
`

const Headline = styled.h1`
    font-size: 5.2vmin;
    font-weight: 900;
    font-family: 'muli', sans-serif;
    line-height: 1;
    margin-bottom: 2rem;
    color: var(--purple);
    /* text-shadow: 1px 1px 0 rgb(34 30 31 / 75%); //1px 1px 1px var(--postColor); */
    
    span.bigger {
        text-transform: uppercase;
        color: white;
        background: var(--orange);
    }

    span.bolder {
        font-weight: 900;
        text-shadow: 1px 1px 0 rgb(34 30 31 / 75%);
    }
    
    ${media.lessThan('large')`
        margin: 0 0 1rem 0;
        font-size: 5vmin;
    `}

    ${media.lessThan('medium')`
        font-size: 6.4vmin;
    `}

    ${media.lessThan("370px")`
        margin-bottom: 1rem;
    `}
`

const SubHeadline = styled.h2`
    font-size: 3vmin;
    font-weight: 600;
    line-height: 1.5;
    color: var(--pink);
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 2rem;
    
    /* text-align: center; */
    span {
        background: var(--white);
    }


    ${media.lessThan("large")`
        span {
            line-height: 1.6;
        }   
    `}
    
    ${media.lessThan("medium")`
        font-size: 4vmin;
        margin: 0;

        span {
            font-size: 3.2vmin;
            line-height: 1.6;
        }
    `}

    ${media.lessThan("400px")`
        /* font-size: 14px; */

        span {
            font-size: 3.1vmin;
            line-height: 1.4;
        }
    `}
`

const ByCharline = styled.h3`
    font-size: 3.5vmin;
    font-weight: 500;
    color: var(--purple);
    font-family: 'Caveat', sans-serif;
    align-self: start;

    ${media.lessThan("large")`
        display: none;
        font-size: 4.5vmin;
    `}
`

const VideoWrapper = styled.div`
    grid-column: 4/-1;
    grid-row: 2/5;
    margin: 2rem;
    
    ${media.lessThan("large")`
        grid-column: 1/-1;
        grid-row: 3/4;
    `}
        
    ${media.lessThan("medium")`
        grid-column: 1/-1;
        grid-row: 3/4;
        margin: 0;
    `}
`

const CTAButtonWrapper = styled.div`
    grid-column: 1/-1; 
    grid-row: 5/6;
    z-index: 100;
    margin: 0 auto;
    
    ${media.lessThan("large")`
        grid-row: 4/5;
        margin: 0 1rem;
    `}
`


const Cover = ({headline, subheadline}) => (
        <CoverCopy>
            <CoverBG />
            <CoverLogo>
                <StaticImage src="../images/logo01.png" alt="logo" />
            </CoverLogo>
            <HeadlineWrapper>
                { headline ? 
                    <Headline>
                        {headline} 
                    </Headline> :
                    <Headline>
                        Essa é a Sua Grande Chance de 
                        <Color color="var(--orange)">
                            <span className="bolder"> EMAGRECER </span>
                        </Color>
                        Sem Passar Fome, e Ainda Sem Deixar de Comer o Que Você Gosta
                    </Headline>
                }
                {/* Emagreça de Forma Saudável, Sem Deixar de Comer o Que Você Gosta Nem Passar Fome */}
                    {/* O Único Método De Emagrecimento 100% Seguro Que Te Fará Perder de 5 a 10kg em Apenas 28 Dias, Sem Deixar de Comer o Que Você Gosta */}
                    {/*"Quer Perder Até 10 Kg em 28 dias, Sem Abrir Mão de Suas Comidas Favoritas, e Sem Passar Fome?" */}
                    {/* Se Você é Mulher e Precisa Emagrecer, Mas Não Quer Passar Fome, Está No Lugar Certo */}
                    {/* Perca de 5 a 10 Kg Em Apenas 4 Semanas - Sem Sofrer Nem Passar Fome */}
                {subheadline ? 
                    <SubHeadline>
                            {subheadline}
                    </SubHeadline> :
                    <SubHeadline>
                        <span>O que vou te apresentar agora é a maneira mais RÁPIDA e SEGURA De Emagrecer, Ganhar Saúde e Alcançar um Corpo Mais Bonito, Comendo Bem! E mais do que isso, é um Passo a Passo Fácil e Prazeroso de Seguir, Que te Trará ECONOMIA e Um Grande BEM ESTAR!</span>
                        {/* Se Você Deseja Emagrecer, Ganhar Saúde e Se Tornar Uma Mulher Mais Atraente, Saudável e Feliz, Mas Não Quer Deixar de Comer Pizza, Chocolate e Outras Delícias, Você Encontrou o Que Procurava. */}
                    </SubHeadline> 
                }
                <ByCharline>por Nutricionista Charline Tormen</ByCharline>
            </HeadlineWrapper>
            <VideoWrapper>
                <Youtube/>
            </VideoWrapper>
            <CTAButtonWrapper>
                <CTAButtonBuy href="https://secure.doppus.com/pay/PZ035MZ035G9OZJH">QUERO EMAGRECER AGORA</CTAButtonBuy>
            </CTAButtonWrapper>
        </CoverCopy>
)

export default Cover;